@use 'setting' as *;

/* ======================================================================
 utility
====================================================================== */

/* =================================
 layout
================================= */
// clearfix
.u_cf {
  @include cf;
}

// pc・sp 出し分け（media-queryはサイトの仕様に合わせて変更すること）
@include pc_tab {
  .u_pc {
    display: block;
    &_i {
      display: inline;
    }
    &_ib {
      display: inline-block;
    }
    &_f {
      display: flex;
    }
    &_if {
      display: inline-flex;
    }
  }
  .u_sp {
    display: none;
    &_i,
    &_ib,
    &_f,
    &_if {
      display: none;
    }
  }
}

@include sp {
  .u_pc {
    display: none;
    &_i,
    &_ib,
    &_f,
    &_if {
      display: none;
    }
  }
  .u_sp {
    display: block;
    &_i {
      display: inline;
    }
    &_ib {
      display: inline-block;
    }
    &_f {
      display: flex;
    }
    &_if {
      display: inline-flex;
    }
  }
}

/* =================================
 content_size
================================= */
.u_content_size {
  padding: 0 $sp_side_padding;
  @include pc_tab {
    padding: 0;
    margin: 0 auto;
    width: $content_width;
  }
}

/* =================================
 text
================================= */
.u_txt_1 {
  font-size: 14px;
  line-height: 24px;
}
.u_txt_2 {
  font-size: 13px;
  line-height: 52px;
  letter-spacing: 0.26px;
}
.u_txt_3 {
  font-size: 15px;
  line-height: 52px;
}
.u_txt_4 {
  font-size: 18px;
  line-height: 24px;
}
.u_txt_14-1 {
  @include fz(14, 22, 0.42);
}
.u_txt_16-1 {
  @include pc_tab {
    @include fz(16, 30);
  }
  @include sp {
    @include fz(16, 28);
  }
}
.u_txt_18-1 {
  @include fz(18, 32);
}
.u_txt_18-2 {
  @include fz(18, 32, 0.36);
}
.u_txt_20-1 {
  @include pc_tab {
    @include fz(20, 34);
  }
  @include sp {
    @include fz(18, 30);
  }
}
.u_txt_32-1 {
  @include pc_tab {
    @include fz(32, 46, 0.64);
  }
  @include sp {
    @include fz(24, 34, 0.48);
  }
}

/* =================================
 text weight
================================= */
.u_lighter {
  font-weight: lighter;
}

.u_bold {
  font-weight: bold;
}

/* =================================
 text align
================================= */
.u_ta_c {
  text-align: center;
}

.u_ta_r {
  text-align: right;
}

.u_ta_cl {
  @include sp {
    text-align: center;
  }
}

.u_ta_lc {
  @include pc_tab {
    text-align: center;
  }
}

.u_ta_cr {
  @include sp {
    text-align: center;
  }
  @include pc_tab {
    text-align: right;
  }
}

.u_ta_rc {
  @include sp {
    text-align: right;
  }
  @include pc_tab {
    text-align: center;
  }
}

/* =================================
 paragraph margin
================================= */
.u_paragraph {
  & + & {
    margin: 15px 0 0;
  }
}

/* =================================
 hover animation
================================= */
.u_hover_1 {
  transition: opacity .3s;
  @include pc {
    &:hover {
      opacity: .7;
    }
  }
}