@use 'setting' as *;

/* ======================================================================
 layout
====================================================================== */

/* =================================
 l_container
================================= */
.l_container {
}

/* =================================
 l_header
================================= */
.l_header {
  position: sticky;
  z-index: 100;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 -65px;
  padding: 15px 40px;
  min-width: $content_width;
  width: 100%;
  height: 65px;
  box-shadow: 0 3px 6px 0 $color_4;
  background: $white;
  transition: 0.3s;
  transform: translate(0, -100%);
  @include sp {
    display: none;
  }
  &.js_is_active {
    transform: translate(0, 0);
  }
  .logo {
    display: block;
  }
  .nav {
    margin: 0 40px 0 auto;
    .list {
      display: flex;
      align-items: center;
      .item {
        &:nth-of-type(n+2) {
          margin: 0 0 0 25px;
        }
      }
    }
  }
  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 172px;
    height: 35px;
    border-radius: 140px;
    background: transparent linear-gradient(180deg, #FFF0A0 0%, #F28736 40%, #EB5100 69%, #F26D0E 75%, #FFA62C 100%) 0% 0% no-repeat padding-box;
    background-size: 100% 180%;
    background-position: center;
    background-repeat: no-repeat;
    box-shadow: 0 2px 0 0 #A23800;
    color: #fff;
    font-weight: 700;
    transition: 0.3s;
    &:hover {
      box-shadow: 0 0 0 0 $color_3;
      transform: translate(0, 2px);
    }
  }
}

/* =================================
 l_fixed_cv
================================= */
.l_fixed_cv {
  position: fixed;
  z-index: 100;
  left: 0;
  bottom: 0;
  display: none;
  align-items: center;
  justify-content: center;
  padding: 10px 20px 10px 10px;
  width: 100vw;
  height: 60px;
  background: $color_5;
  @include sp {
    display: flex;
  }
  .image {
    position: relative;
    z-index: 1;
    width: 97px;
  }
  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: -3px 0 0 0;
    padding: 0 16px 1px 26px;
    width: 80.066667vw;
    height: 45px;
    border-radius: 60px;
    background: transparent linear-gradient(180deg, #FFF0A0 0%, #F28736 40%, #EB5100 69%, #F26D0E 75%, #FFA62C 100%) 0% 0% no-repeat padding-box;
    background-size: 100% 180%;
    background-position: center;
    background-repeat: no-repeat;
    box-shadow: 0 3px 0 0 #A23800;
    white-space: nowrap;
    color: #fff;
    font-size: 17px;
    font-weight: 700;
    transition: 0.3s;
    &::before {
      position: absolute;
      top: 22px;
      right: 91px;
      content: "";
      border-bottom: solid 2px #fff;
      border-right: solid 2px #fff;
      width: 10px;
      height: 10px;
      transform: rotate(45deg);
    }

  }
  .hamburger_button {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 0 0 auto;
    width: 25px;
    height: 20px;
    .item {
      width: 100%;
      height: 2px;
      background: $white;
    }
  }
}

/* =================================
 l_sp_nav
================================= */
.l_sp_nav {
  position: fixed;
  z-index: 101;
  top: 0;
  left: 0;
  display: none;
  width: 100vw;
  height: 100vh;
  background: $color_6;
  &.js_is_active {
    display: block;
  }
  .nav {
    margin: 0 0 0 auto;
    width: 243px;
    height: 100%;
    padding: 100px 35px;
    background: $color_5;
    .list {
      .item {
        &:nth-of-type(n+2) {
          margin: 30px 0 0;
        }
        .link {
          @include a_tag($white, $white);
          &.current {
            @include a_tag($color_2, $color_2);
          }
        }
      }
    }
  }
  .hamburger_button {
    position: fixed;
    top: 17px;
    right: 17px;
    width: 27px;
    height: 27px;
    .item {
      display: block;
      width: 100%;
      height: 2px;
      background: $white;
      &:nth-of-type(1) {
        transform: rotate(-45deg);
      }
      &:nth-of-type(2) {
        transform: rotate(45deg) translate(-1px, -1px);
      }
    }
  }
}

/* =================================
 l_main_visual
================================= */
.l_main_visual {
}

/* =================================
 l_content
================================= */
.l_content {
}

/* =================================
 l_main
================================= */
.l_main {
}

/* =================================
 l_footer
================================= */
.l_footer {
  @include sp {
    padding: 0 0 60px;
  }
  .policy {
    background: linear-gradient(94deg, #0777CC, #043C66);
  }

  .policy-container {
    @include pc_tab {
      padding: 30px;
    }
  }

  .policy-linkbox {
    @include pc_tab {
      display: flex;
      justify-content: center
    }
    @include sp {
      padding: 25px 15px;
    }
  }

  .policy-link {
    @include a_tag($white, $white);
    position: relative;
    transition: opacity .3s;
    @include pc {
      &:hover {
        border-bottom: none;
        opacity: .6;
      }
    }
    @include pc_tab {
      @include fz(16, 28);
    }
    @include sp {
      @include fz(14, 28);
    }
    &:not(:first-child) {
      @include pc_tab {
        margin-left: 20px;
      }
      @include sp {
        display: inline-block;
        margin-top: 20px;
      }
    }
    &[target="_blank"] {
      position: relative;
      margin-right: 20px;
      transition: all .3s;
      &::before {
        position: absolute;
        top: 100%;
        left: 0;
        width: calc(100% + 20px);
        height: 1px;
        content: "";
        background: #fff;
        transition: all .3s;
      }
      &:hover {
        opacity: .5;
        transition: all .3s;
        &::before {
          content: "";
          background: none;
          transition: all .3s;
        }
      }
      &:after {
        content: '';
        position: absolute;
        top: 8px;
        right: -16px;
        display: inline-block;
        width: 13px;
        height: 13px;
        background: url(#{$img_dir}/icon-blank-white.svg) no-repeat top center / contain;
      }
      &:first-child {
        &:after {
          @include sp {
            top: 4px;
          }
        }
      }
    }
  }

  .copy {
    background: $black_2;
  }

  .copy-txt {
    display: block;
    padding: 8px 0 10px;
    color: $white;
    @include fz(12, 25);
  }
}